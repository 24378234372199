import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "SEO und Subdomains",
  "description": "Wie beeinflusst das Karriereportal mein bestehendes Suchmaschinen-Ranking?",
  "author": "Jochen Ritscher",
  "categories": ["faqs"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false,
  "tags": ["browser"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Wir empfehlen zur Einrichtung stets das Verwenden einer Subdomain wie karriere.ihrunternehmen.de oder jobs.ihrunternehmen.de `}</p>
    <p>{`In vielen Fällen sind Stellenanzeigen vor dem Start des Karriereportals bisher auf Ihrer Hauptseite abgelegt. Wie verhält sich der Umzug der Stellenanzeigen auf das Karriereportal? Habe ich Nachteile z.B. im Google PageRank durch die neue Subdomain?`}</p>
    <p>{`Google selbst beschreibt grundsätzlich beide Varianten (Stellenanzeigen in einem Unterverzeichis Ihrer Hauptdomain oder Subdomain) gleichgestellt sind. Die Entscheidung sollte laut Google davon abhängen, mit welcher man einfacher umgehen bzw. arbeiten kann.`}</p>
    <p>{`John Mueller/Google beschreibt im folgenden Interview, dass eine beschränkte Anzahl von Subdomains gleichwertig zu einem Unterverzeichnis behandelt werden.  Einzig von einer massiven Anzahl Wildcard-Subdomains rät er ab, da diese durch den Google-Algorithmus separat behandelt werden. `}<a parentName="p" {...{
        "href": "https://www.seroundtable.com/google-treat-subdomains-vs-subdirectories-22485.html"
      }}>{`https://www.seroundtable.com/google-treat-subdomains-vs-subdirectories-22485.html`}</a></p>
    <p>{`Bei der Änderung einer Domain kann der bereits erreichte PageRank und der Trust zusätzlich explizit auf die neue Internetadresse übertragen werden. Hierfür sind allerdings einige Anpassungen nötig. Die wichtigsten Schritte sind:`}</p>
    <p>{`Verknüpfen Sie den Content mit der neuen Domain.`}</p>
    <p>{`Verifizieren Sie die neue Adresse bei Google Search Console (früher Google Webmaster Tools) und geben Sie anschließend die Adressänderung Ihrer Website an. Dies muss mit dem gleichen Konto geschehen, unter dem bereits die alte Domain registriert ist.`}</p>
    <p>{`Richten Sie eine 301-Weiterleitung von der alten zur neuen Adresse ein`}</p>
    <p>{`Geben Sie über Google Search Console die XML-Sitemap Ihrer neuen Website an. Es empfiehlt sich, sofort das Crawlen der Sitemap zu veranlassen, damit die Google-Bots dies sobald wie möglich ausführen.`}</p>
    <p>{`Beobachten Sie anschließend über mehrere Wochen über die Google Search Console (und bestenfalls weitere Analyse-Tools) die Statistiken Ihrer Website mit neuer Domain.`}</p>
    <p>{`Sie haben weitere Fragen? Sprechen Sie uns an unter `}<a parentName="p" {...{
        "href": "mailto:mail@inriva.com"
      }}>{`mail@inriva.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      